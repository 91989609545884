<template>
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Contacts</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-primary"
            data-bs-toggle="modal"
            data-bs-target="#addContactSelectModal"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="permitContacts" />
      <!-- Card body -->
      <div v-if="permitContacts" class="card-body pt-7 h-300px overflow-scroll">
        <div
          v-if="permitContacts.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div v-for="contact in permitContacts.results" :key="contact.id">
            <div class="mb-4 d-flex justify-content-between align-items-center">
              <div>
                <router-link :to="'/contacts/' + contact.id" class="mb-0">
                  {{ contact.last_name }} {{ contact.first_name }}
                  <span
                    v-if="contact.relation_quality"
                    class="ms-2 badge bg-light-warning text-dark"
                  >
                    {{ contact.relation_quality }}
                    <i class="bi bi-star-fill text-warning h6"></i>
                  </span>
                </router-link>
                <div v-if="contact.position">
                  Poste : {{ contact.position }}
                </div>
                <div v-if="contact.phone_number">
                  Téléphone : {{ contact.phone_number }}
                </div>
                <div v-if="contact.last_followup_author">
                  Dernière personne à l'avoir contacté :
                  {{ contact.last_followup_author }}
                </div>
                <div v-if="contact.promoter" class="text-gray-700">
                  Promoteur :
                  <router-link :to="'/promoters/' + contact.promoter.id">{{
                    contact.promoter.name
                  }}</router-link>
                </div>
                <div v-if="contact.agency__name">
                  Agence :
                  <router-link :to="'/agencies/' + contact.agency.id">{{
                    contact.agency__name
                  }}</router-link>
                </div>
                <div v-else>Aucune agence</div>
                <div>
                  <div v-if="principal_contact">
                    <span
                      v-if="principal_contact.id == contact.id"
                      class="text-danger"
                      >Contact principal</span
                    >
                    <span
                      v-else
                      class="text-success"
                      role="button"
                      @click.prevent="updatePrincipalContact(contact.id)"
                      >Définir comme contact principal</span
                    >
                  </div>
                  <div v-else>
                    <span
                      class="text-success"
                      role="button"
                      @click.prevent="updatePrincipalContact(contact.id)"
                      >Définir comme contact principal</span
                    >
                  </div>
                </div>
              </div>
              <div>
                <span
                  class="btn svg-icon svg-icon-danger svg-icon-2"
                  @click.prevent="deleteContact(contact.id)"
                >
                  <i
                    class="bi bi-trash-fill text-danger"
                    style="font-size: 1.2rem"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2 text-gray-700">
          Aucun contact associé à ce permis
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <div
    id="addContactSelectModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="addContactSelectModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un contact</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <CloseIcon />
            </span>
          </div>
        </div>
        <!-- Modal form -->
        <form @submit.prevent="linkContact">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div v-if="contacts" class="mb-5 fv-row">
              <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>Contacts</span>
              </label>
              <div class="d-flex">
                <v-select
                  v-model="formLinkContact.contactSelected.id"
                  class="form-control me-4"
                  :reduce="(contact) => contact.id"
                  :options="arrayContacts"
                  label="label"
                  placeholder="Choisir un contact"
                  @search="fetchOptionsContact"
                />
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addContactModal"
                >
                  +
                </button>
              </div>
            </div>
            <div v-else>
              <Loader :data="contacts" />
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Add contact form modal -->
  <div
    id="addContactModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="addContactModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un contact</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <!-- Modal form -->
        <form @submit.prevent="createContact">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="required fs-5 fw-bold mb-2">Nom</label>
                <input
                  v-model="formContact.last_name"
                  type="text"
                  class="form-control form-control-solid"
                />
              </div>
              <div class="col-md-6 fv-row">
                <label class="required fs-5 fw-bold mb-2">Prénom</label>
                <input
                  v-model="formContact.first_name"
                  type="text"
                  class="form-control form-control-solid"
                />
              </div>
            </div>
            <div class="row mb-5">
              <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>Promoteur</span>
              </label>
              <div class="d-flex">
                <PromotersSelect @selected="selectPromoterOption" />
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsepromoter"
                  aria-expanded="false"
                  aria-controls="collapsepromoter"
                >
                  +
                </button>
              </div>
              <div id="collapsepromoter" class="collapse">
                <div class="row mt-5 mb-5">
                  <div class="col-md-6 fv-row">
                    <label class="required fs-5 fw-bold mb-2"
                      >Nom du promoteur</label
                    >
                    <input
                      v-model="formPromoter.name"
                      type="text"
                      class="form-control form-control-solid"
                    />
                  </div>
                  <div class="col-md-6 fv-row">
                    <label class="required fs-5 fw-bold mb-2"
                      >Nom du dirigeant</label
                    >
                    <input
                      v-model="formPromoter.director_name"
                      type="text"
                      class="form-control form-control-solid"
                    />
                  </div>
                </div>
                <div>
                  <label class="required fs-5 fw-bold mb-2 d-flex"
                    >Addresse du promoteur</label
                  >
                  <div class="d-flex">
                    <AddressInput
                      :can-create-address="canCreatePromoterAddress"
                      @addressCreated="catchPromoterAddressId"
                    ></AddressInput>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-primary mt-5"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsepromoter"
                    aria-expanded="false"
                    aria-controls="collapsepromoter"
                    :disabled="
                      formPromoter.name && formPromoter.director_name
                        ? false
                        : true
                    "
                    @click.prevent="createAddressThenPromoter()"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
            <div v-if="agencies" class="d-flex flex-column mb-5 fv-row">
              <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>Agence</span>
              </label>
              <div class="d-flex">
                <AgenciesSelect @selected="selectAgencyOption" />
                <button
                  class="btn btn-primary"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseAgency"
                  aria-expanded="false"
                  aria-controls="collapseAgency"
                >
                  +
                </button>
              </div>
              <div id="collapseAgency" class="collapse">
                <div class="row mt-5 mb-5">
                  <div class="col-md-6 fv-row">
                    <label class="required fs-5 fw-bold mb-2"
                      >Nom de l'agence</label
                    >
                    <input
                      v-model="formAgency.name"
                      type="text"
                      class="form-control form-control-solid"
                    />
                  </div>
                  <div class="col-md-6 fv-row">
                    <label class="required fs-5 fw-bold mb-2"
                      >Numéro de téléphone</label
                    >
                    <input
                      v-model="formAgency.phone_number"
                      v-mask="'00 00 00 00 00'"
                      type="text"
                      class="form-control form-control-solid"
                    />
                  </div>
                </div>
                <div class="mb-5">
                  <label class="required fs-5 fw-bold mb-2"
                    >Adresse de l'agence</label
                  >
                  <AddressInput
                    :can-create-address="canCreateAgencyAddress"
                    @addressCreated="catchAgencyAddressId"
                  ></AddressInput>
                </div>
                <div>
                  <label class="required fs-5 fw-bold mb-2">Promoteur</label>
                  <PromotersSelect @selected="selectAgencyPromoterOption" />
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-primary mt-5"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseAgency"
                    aria-expanded="false"
                    aria-controls="collapseAgency"
                    @click.prevent="createAddressThenAgency()"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-md-6 fv-row">
                <label class="required fs-5 fw-bold mb-2"
                  >Numéro de téléphone</label
                >
                <input
                  v-model="formContact.phone_number"
                  v-mask="'00 00 00 00 00'"
                  type="text"
                  class="form-control form-control-solid"
                />
              </div>
              <div class="col-md-6 fv-row">
                <label class="required fs-5 fw-bold mb-2"
                  >Second téléphone</label
                >
                <input
                  v-model="formContact.second_phone_number"
                  v-mask="'00 00 00 00 00'"
                  type="text"
                  class="form-control form-control-solid"
                />
              </div>
            </div>
            <div class="d-flex flex-column mb-5 fv-row">
              <label class="fs-5 fw-bold mb-2">Email</label>
              <input
                v-model="formContact.email"
                class="form-control form-control-solid"
              />
            </div>
            <div class="d-flex flex-column mb-5 fv-row">
              <label class="fs-5 fw-bold mb-2">Poste</label>
              <select
                v-model="formContact.position"
                class="form-select form-select-solid form-select-lg me-4"
              >
                <option value="Assistant de Direction">
                  Assistant de Direction
                </option>
                <option value="Assistant de programme">
                  Assistant de programme
                </option>
                <option value="Développeur">Développeur</option>
                <option value="Directeur">Directeur</option>
                <option value="Directeur Associé">Directeur Associé</option>
                <option value="Directeur commercial">
                  Directeur commercial
                </option>
                <option value="Directeur d'Agence">Directeur d'Agence</option>
                <option value="Directeur de développement">
                  Directeur de développement
                </option>
                <option value="Directeur de programme">
                  Directeur de programme
                </option>
                <option value="Directeur des Partenariats">
                  Directeur des Partenariats
                </option>
                <option value="Directeur des ventes bloc">
                  Directeur des ventes bloc
                </option>
                <option value="Directeur Général">Directeur Général</option>
                <option value="Directeur Général Adjoint">
                  Directeur Général Adjoint
                </option>
                <option value="Gérant">Gérant</option>
                <option value="Directeur Régional">Directeur Régional</option>
                <option value=" Directeur technique">
                  Directeur technique
                </option>
                <option value="Directeur Opérationnel">
                  Directeur Opérationnel
                </option>
                <option value="Fondateur">Fondateur</option>
                <option value="Juriste">Juriste</option>
                <option value="Président">Président</option>
                <option value="Responsable administration des ventes">
                  Responsable administration des ventes
                </option>
                <option value="Responsable">Responsable</option>
                <option value="Responsable de développement">
                  Responsable de développement
                </option>
                <option value="Responsable de programme">
                  Responsable de programme
                </option>
              </select>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import CloseIcon from "@/components/icons/CloseIcon";
import PromotersSelect from "@/components/selects/PromotersSelect";
import AgenciesSelect from "@/components/selects/AgenciesSelect";
import AddressInput from "@/components/selects/AddressInput";

export default {
  name: "PermitContacts",
  components: {
    Loader,
    CloseIcon,
    PromotersSelect,
    AgenciesSelect,
    AddressInput,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    principal_contact: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formCompleted: {
        id: null,
        contact: [],
      },
      arrayPromoters: [],
      arrayContacts: [],
      formContact: {
        id: null,
        first_name: "",
        last_name: "",
        phone_number: "",
        second_phone_number: "",
        email: "",
        position: "",
        promoter_id: "",
        agency_id: "",
      },
      formLinkContact: {
        id: null,
        contact: [],
        contactSelected: {
          id: null,
        },
      },
      formPromoter: {
        id: null,
        name: "",
        director_name: "",
        head_office_address: "",
        DAU: "",
      },
      formAgency: {
        name: "",
        phone_number: "",
        promoter: "",
        address: "",
      },
      formPermit: {
        id: this.id,
        principal_contact: "",
      },
      canCreatePromoterAddress: false,
      canCreateAgencyAddress: false,
    };
  },
  computed: {
    ...mapGetters(["permitContacts"]),
    ...mapGetters(["promoters"]),
    ...mapGetters(["agencies"]),
    ...mapGetters(["permit"]),
    ...mapGetters(["addresses"]),
    ...mapGetters(["contacts"]),
  },
  mounted() {
    this.getAllPermitContacts();
    this.getAllAgencies();
    this.getAllAddresses();
    this.getAllContacts();
  },
  methods: {
    ...mapActions(["getPermitContacts"]),
    ...mapActions(["postContact"]),
    ...mapActions(["postPromoter"]),
    ...mapActions(["postAgency"]),
    ...mapActions(["getAgencies"]),
    ...mapActions(["getAddresses"]),
    ...mapActions(["updatePermit"]),
    ...mapActions(["updatePermitPrincipalContact"]),
    ...mapActions(["getContactsInPermit"]),
    ...mapActions(["searchPromoter"]),
    ...mapActions(["searchContact"]),
    ...mapActions(["getPermit"]),

    // Promoter
    async createAddressThenPromoter() {
      await this.createPromoterAdrress();
      this.canCreatePromoterAddress = false;
    },
    createPromoterAdrress() {
      this.canCreatePromoterAddress = true;
    },
    catchPromoterAddressId(value) {
      this.formPromoter.head_office_address = value;
      this.canCreatePromoterAddress = false;
      this.postPromoter(this.formPromoter);
    },

    // Agency
    async createAddressThenAgency() {
      await this.createAgencyAdress();
      this.canCreateAgencyAddress = false;
    },
    createAgencyAdress() {
      this.canCreateAgencyAddress = true;
    },
    catchAgencyAddressId(value) {
      this.formAgency.address = value;
      this.canCreateAgencyAddress = false;
      this.postAgency(this.formAgency);
      this.getAllAgencies();
    },

    async getAllPermitContacts() {
      await this.getPermitContacts(this.id);
    },
    async createContact() {
      let contact_id = await this.postContact(this.formContact);
      if (contact_id) {
        this.formCompleted.contact.push(contact_id);
        this.formCompleted.id = this.id;
        let data = this.permit.contacts;
        data.forEach((contactId) => {
          this.formCompleted.contact.push(contactId);
        });
        await this.updatePermit(this.formCompleted);
      }
      await this.getAllPermitContacts();
    },
    async linkContact() {
      let data = [];
      this.permitContacts.results.forEach((permit) => {
        data.push(permit.id);
      });
      data.push(this.formLinkContact.contactSelected.id);
      this.formLinkContact.id = this.id;
      this.formLinkContact.contact = data;
      await this.updatePermit(this.formLinkContact);
      await this.getAllPermitContacts();
    },
    async deletePrincipalContact(id) {
      if (this.permit.principal_contact.id == id) {
        await this.updatePrincipalContact(null);
      }
    },
    async deleteContact(id) {
      let data = [];
      this.permitContacts.results.forEach((permit) => {
        data.push(permit.id);
      });
      this.formLinkContact.id = this.id;
      this.formPermit.id = this.id;
      let newData = data.filter((element) => element != id);
      this.formLinkContact.contact = newData;
      this.deletePrincipalContact(id);
      await this.updatePermit(this.formLinkContact);
      await this.getAllPermitContacts();
    },
    getAllAgencies() {
      this.getAgencies();
    },
    getAllAddresses() {
      this.getAddresses();
    },
    async updatePrincipalContact(id) {
      this.formPermit.principal_contact = id;
      await this.updatePermitPrincipalContact(this.formPermit);
      this.getAllPermitContacts();
      this.getPermit(this.id);
    },
    // Addresses
    selectNewAddresse(id) {
      this.getAddresses();
      this.formAgency.address = id;
    },
    async getAllContacts() {
      await this.getContactsInPermit();
      let array = [];
      this.contacts.results.forEach((element) => {
        array.push({
          label: element.first_name + " " + element.last_name,
          id: element.id,
        });
      });
      this.arrayContacts = array;
    },
    selectPromoterOption(element) {
      this.formContact.promoter_id = element;
    },
    selectPromoterAddressOption(element) {
      this.formPromoter.head_office_address = element;
    },
    selectAgencyOption(element) {
      this.formContact.agency_id = element;
    },
    selectAgencyPromoterOption(element) {
      this.formAgency.promoter = element;
    },
    selectAddressOption(element) {
      this.formAgency.address = element;
    },
    async fetchOptionsContact(search, loading) {
      if (search.length) {
        loading(true);
        let array = [];
        await this.searchContact(search);
        this.contacts.results.forEach((element) => {
          array.push({
            label: element.first_name + " " + element.last_name,
            id: element.id,
          });
        });
        this.arrayContacts = array;
        loading(false);
      }
    },
  },
};
</script>
