<template>
  <!-- Permit surfaces -->
  <div class="col-md-12">
    <div class="card h-md-100">
      <!-- Card header -->
      <div class="card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Tableau des surfaces</h3>
        </div>
        <div class="d-flex justify-content-end">
          <div class="me-3 d-flex align-items-center">
            <input
              v-model="scrollValue"
              type="range"
              min="0"
              :max="maxScroll"
              class="form-range"
              @input="scrollTable"
            />
          </div>
        </div>
      </div>
      <div class="card-body p-0">
        <div
          ref="tableResponsiveSurfaces"
          class="table-responsive"
          @scroll="onTableScroll"
        >
          <table
            class="table table-flush align-middle table-row-bordered table-row-solid gy-4"
          >
            <thead>
              <tr class="text-start fw-bolder fs-6 text-uppercase gs-0 mr-5">
                <th class="min-w-125px ps-9">NB_LGT_IND_CREES</th>
                <th class="min-w-125px ps-9">NB_LGT_COL_CREES</th>
                <th class="min-w-125px ps-9">NB_LGT_DEMOLIS</th>
                <th class="min-w-125px ps-9">NB_LGT_1P</th>
                <th class="min-w-125px ps-9">NB_LGT_2P</th>
                <th class="min-w-125px ps-9">NB_LGT_3P</th>
                <th class="min-w-125px ps-9">NB_LGT_4P</th>
                <th class="min-w-125px ps-9">NB_LGT_5P</th>
                <th class="min-w-125px ps-9">NB_LGT_6P_PLUS</th>
              </tr>
            </thead>
            <tbody class="fw-bold text-gray-700">
              <tr class="w-250px min-w-175px ps-9">
                <td>
                  <span v-if="permit.nb_lgt_ind_crees">
                    {{ permit.nb_lgt_ind_crees }}
                  </span>
                  <span v-else>Information non fournie</span>
                </td>
                <td>
                  <span v-if="permit.nb_lgt_col_crees">
                    {{ permit.nb_lgt_col_crees }}</span
                  >
                  <span v-else>Information non fournie</span>
                </td>
                <td>
                  <span v-if="permit.nb_lgt_demolis">
                    {{ permit.nb_lgt_demolis }}</span
                  >
                  <span v-else>Information non fournie</span>
                </td>
                <td>
                  <span v-if="permit.nb_lgt_1p">{{ permit.nb_lgt_1p }}</span>
                  <span v-else>Information non fournie</span>
                </td>
                <td>
                  <span v-if="permit.nb_lgt_2p">{{ permit.nb_lgt_2p }}</span>
                  <span v-else>Information non fournie</span>
                </td>
                <td>
                  <span v-if="permit.nb_lgt_3p">{{ permit.nb_lgt_3p }}</span>
                  <span v-else>Information non fournie</span>
                </td>
                <td>
                  <span v-if="permit.nb_lgt_4p">{{ permit.nb_lgt_4p }}</span>
                  <span v-else>Information non fournie</span>
                </td>
                <td>
                  <span v-if="permit.nb_lgt_5p">{{ permit.nb_lgt_5p }}</span>
                  <span v-else>Information non fournie</span>
                </td>
                <td>
                  <span v-if="permit.nb_lgt_6p_plus">{{
                    permit.nb_lgt_6p_plus
                  }}</span>
                  <span v-else>Information non fournie</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "PermitsSurfaces",
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      scrollValue: 0,
      maxScroll: 100,
    };
  },
  computed: {
    ...mapGetters(["permit"]),
  },
  methods: {
    ...mapActions(["getPermit"]),
    showPermit(id) {
      this.getPermit(id);
    },
    scrollTable() {
      const tableContainer = this.$refs.tableResponsiveSurfaces;
      if (tableContainer) {
        const scrollPercentage = this.scrollValue / this.maxScroll;
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        const scrollAmount = maxScrollAmount * scrollPercentage;
        tableContainer.scrollLeft = scrollAmount;
      }
    },
    onTableScroll() {
      const tableContainer = this.$refs.tableResponsiveSurfaces;
      if (tableContainer) {
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        if (maxScrollAmount > 0) {
          const scrollPercentage = tableContainer.scrollLeft / maxScrollAmount;
          this.scrollValue = scrollPercentage * this.maxScroll;
        }
      }
    },
  },
};
</script>
