<template>
  <!-- Address input -->
  <v-select
    ref="test"
    v-model="selectedAddress"
    class="form-control form-control-solid"
    :options="searchResults"
    placeholder="Rechercher une adresse"
    :rules="[requiredRule]"
    @search="onSearch"
  >
    <template #no-options>
      <v-menu v-if="searchResults.length > 0">
        <template #activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            readonly
            :value="'Aucune adresse trouvée'"
            v-on="on"
          ></v-text-field>
        </template>
        <v-list>
          <v-list-item
            v-for="(result, index) in searchResults"
            :key="index"
            class="d-flex justify-content-start text-align-start custom-list-item"
            @click="addressClick(result)"
          >
            <v-list-item-title
              class="ps-6"
              style="
                padding-top: 0.2rem !important;
                padding-bottom: 0.2rem !important;
              "
              >{{ result.label }}</v-list-item-title
            ><br />
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu v-else>Aucune adresse trouvée</v-menu></template
    >
  </v-select>
</template>

<script>
import store from "../../store/index";
import { mapActions } from "vuex";

export default {
  name: "AddressInput",
  props: {
    canCreateAddress: Boolean,
    addressSelected: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      formAddresses: {
        number: "",
        street: "",
        city: "",
        zipcode: "",
      },
      selectedAddress: null,
      searchResults: [],
      customAddress: "",
    };
  },
  watch: {
    canCreateAddress() {
      if (this.canCreateAddress) {
        if (
          !this.formAddresses.number ||
          !this.formAddresses.street ||
          !this.formAddresses.city ||
          !this.formAddresses.zipcode
        ) {
          store.commit("message", {
            status: "error",
            text: "L'addresse est obligatoire !",
          });
        } else {
          this.createAddresses();
        }
      }
    },
    selectedAddress() {
      if (
        this.selectedAddress &&
        this.selectedAddress.number &&
        this.selectedAddress.street &&
        this.selectedAddress.city &&
        this.selectedAddress.zipcode
      ) {
        this.formAddresses.number = this.selectedAddress.number;
        this.formAddresses.street = this.selectedAddress.street;
        this.formAddresses.city = this.selectedAddress.city;
        this.formAddresses.zipcode = this.selectedAddress.zipcode;
      }
    },
    addressSelected() {
      if (this.addressSelected && this.addressSelected != "") {
        if (!this.selectedAddress) {
          this.selectedAddress = {};
        }
        const { street, number, zipcode, city } = this.addressSelected;
        this.selectedAddress.number = number ? number : "";
        this.selectedAddress.street = street;
        this.selectedAddress.zipcode = zipcode;
        this.selectedAddress.city = city;
        this.selectedAddress.label =
          this.selectedAddress.number +
          " " +
          street +
          " " +
          zipcode +
          " " +
          city;
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["postAddress"]),

    async onSearch(query) {
      const apiUrl = `https://api-adresse.data.gouv.fr/search/?q=${query}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        this.searchResults = data.features.map((result) => ({
          label: result.properties.label,
          number: result.properties.housenumber
            ? result.properties.housenumber
            : "",
          street: result.properties.street,
          city: result.properties.city,
          zipcode: result.properties.postcode,
        }));
      } catch (error) {
        console.error("Erreur lors de la recherche :", error);
      }
    },
    async createAddresses() {
      if (parseInt(this.formAddresses.number)) {
        let address = await this.postAddress(this.formAddresses);
        this.$emit("addressCreated", address.id);
        this.selectedAddress = null;
        this.formAddresses = {};
      }
    },
    addressClick(value) {
      this.$refs.test.search = value.label;
      this.selectedAddress = value;
    },
  },
};
</script>
<style scoped>
.custom-list-item:hover {
  background-color: #5897fb;
  color: white;
  cursor: pointer;
}
</style>
