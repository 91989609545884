<template>
  <v-select
    v-if="promoters"
    v-model="model"
    class="form-control form-control-solid form-control-lg me-6"
    :reduce="(promoter) => promoter.id"
    :options="arrayPromoters"
    label="label"
    placeholder="Promoteur"
    @option:selected="$emit('selected', model)"
    @search="fetchOptionsPromoter"
  />
  <div v-else class="col-lg-10 fv-row">
    <Loader :data="promoters" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "PromotersSelect",
  components: {
    Loader,
  },
  data: function () {
    return {
      arrayPromoters: [],
      model: "",
    };
  },
  computed: {
    ...mapGetters(["promoters"]),
  },
  methods: {
    ...mapActions(["getPromotersInSelect"]),
    ...mapActions(["searchPromoter"]),
    async getAllPromoters() {
      await this.getPromotersInSelect();
      let array = [];
      this.promoters.results.forEach((element) => {
        array.push({
          label: element.name,
          id: element.id,
        });
      });
      this.arrayPromoters = array;
    },
    async fetchOptionsPromoter(search, loading) {
      if (search.length) {
        loading(true);
        let array = [];
        await this.searchPromoter(search);
        this.promoters.results.forEach((element) => {
          array.push({
            label: element.name,
            id: element.id,
          });
        });
        this.arrayPromoters = array;
        loading(false);
      }
    },
  },
  mounted() {
    this.getAllPromoters();
  },
};
</script>
