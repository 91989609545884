<template>
  <!-- Prospection commerciale -->
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header -->
      <div class="card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Prospection commerciale</h3>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="permit" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-5">
            <div class="d-flex justify-content-between fw-bolder text-black">
              <span>Référents</span>
              <button
                v-if="form.referrers.length !== 0"
                class="btn btn-color-danger p-0"
                @click.prevent="deleteReferrers()"
              >
                <i class="bi bi-x-circle-fill" style="font-size: 1.2rem"></i>
              </button>
            </div>
            <form class="form pt-2" @change="updateProspection()">
              <Loader :data="users" />
              <select
                v-if="users"
                v-model="form.referrers"
                class="form-select form-select-solid"
                multiple
              >
                <option
                  v-for="user in users.results"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.first_name }} {{ user.last_name }}
                </option>
              </select>
            </form>
          </div>
          <div class="mb-5">
            <div class="d-flex justify-content-between fw-bolder text-black">
              <span>Étape de discussion</span>
              <button
                v-if="form.discussion_stage"
                class="btn btn-color-danger p-0"
                @click.prevent="deleteDiscussionStage()"
              >
                <i class="bi bi-x-circle-fill" style="font-size: 1.2rem"></i>
              </button>
            </div>
            <form class="d-flex form pt-2" @change="updateProspection()">
              <select
                v-model="form.discussion_stage"
                class="form-select form-select-solid form-select-lg"
              >
                <option value="Premier contact">Premier contact</option>
                <option value="RDV fait">RDV fait</option>
                <option value="Convaincu">Convaincu</option>
                <option value="Valo faite">Valo faite</option>
                <option value="Contrat envoyé">Contrat envoyé</option>
                <option value="Contrat signé">Contrat signé</option>
              </select>
            </form>
          </div>
          <div class="mb-5">
            <div class="d-flex justify-content-between fw-bolder text-black">
              <span>Situation de l'opération</span>
              <button
                v-if="form.specific_case"
                class="btn btn-color-danger p-0"
                @click.prevent="deleteSpecficCase()"
              >
                <i class="bi bi-x-circle-fill" style="font-size: 1.2rem"></i>
              </button>
            </div>
            <form class="form pt-2" @change="updateProspection()">
              <select
                v-model="form.specific_case"
                class="form-select form-select-solid form-select-lg"
              >
                <option value="Bailleur social ou assimilé">
                  Bailleur social ou assimilé
                </option>
                <option value="Introuvable">Introuvable</option>
                <option value="LLS vendus">LLS vendus</option>
                <option value="Opération achevée">Opération achevée</option>
                <option value="Opération abandonnée">
                  Opération abandonnée
                </option>
                <option value="Permis favoris">Permis favoris</option>
                <option value="Intéressant vente bloc">
                  Intéressant vente bloc
                </option>
                <option value="Pas de LLS">Pas de LLS</option>
                <option value="Pas compétitifs">Pas compétitifs</option>
                <option value="Négociation en cours">
                  Négociation en cours
                </option>
                <option value="Opération signée">Opération signée</option>
                <option value="Promoteur indélicat">Promoteur indélicat</option>
                <option value="LLS « dealé »">LLS « dealé »</option>
                <option value="Autre non pertinent">Autre non pertinent</option>
              </select>
            </form>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "CommercialProspecting",
  components: {
    Loader,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      form: {
        id: this.id,
        discussion_stage: "",
        specific_case: "",
        referrers: [],
      },
    };
  },
  computed: {
    ...mapGetters(["permit"]),
    ...mapGetters(["users"]),
  },
  methods: {
    ...mapActions(["getPermit"]),
    ...mapActions(["getUsers"]),
    ...mapActions(["updatePermitProspection"]),
    async showPermit(id) {
      await this.getPermit(id);
      if (this.permit.referrers.length > 0) {
        for (let i = 0; i < this.permit.referrers.length; i++) {
          const element = this.permit.referrers[i].id;
          this.form.referrers[i] = element;
        }
      }
      this.form.discussion_stage = this.permit.discussion_stage;
      this.form.specific_case = this.permit.specific_case;
    },
    async getAllUsers() {
      await this.getUsers();
    },
    updateProspection() {
      this.updatePermitProspection(this.form);
      this.getPermit(this.id);
    },
    deleteReferrers() {
      this.form.referrers = [];
      this.updatePermitProspection(this.form);
      this.getPermit(this.id);
    },
    deleteDiscussionStage() {
      this.form.discussion_stage = null;
      this.updatePermitProspection(this.form);
      this.getPermit(this.id);
    },
    deleteSpecficCase() {
      this.form.specific_case = "Aucun cas spécifique";
      this.updatePermitProspection(this.form);
      this.getPermit(this.id);
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    this.getAllUsers();
    this.showPermit(this.id);
  },
};
</script>
