<template>
  <v-select
    v-if="agencies"
    v-model="model"
    class="form-control form-control-solid form-control-lg me-6"
    :reduce="(agency) => agency.id"
    :options="arrayAgencies"
    label="label"
    placeholder="Agence"
    @option:selected="$emit('selected', model)"
    @search="fetchOptionsAgency"
  />
  <div v-else class="col-lg-10 fv-row">
    <Loader :data="agencies" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "AgenciesSelect",
  components: {
    Loader,
  },
  data: function () {
    return {
      arrayAgencies: [],
      model: "",
    };
  },
  computed: {
    ...mapGetters(["agencies"]),
  },
  methods: {
    ...mapActions(["getAgenciesInSelect"]),
    ...mapActions(["searchAgency"]),
    async getAllAgencies() {
      await this.getAgenciesInSelect();
      let array = [];
      this.agencies.results.forEach((element) => {
        array.push({
          label: element.name,
          id: element.id,
        });
      });
      this.arrayAgencies = array;
    },
    async fetchOptionsAgency(search, loading) {
      if (search.length) {
        loading(true);
        let array = [];
        await this.searchAgency(search);
        this.agencies.results.forEach((element) => {
          array.push({
            label: element.name,
            id: element.id,
          });
        });
        this.arrayAgencies = array;
        loading(false);
      }
    },
  },
  mounted() {
    this.getAllAgencies();
  },
};
</script>
