<template>
  <div v-if="data === null" class="container-xxl">
    <div class="text-center my-3">
      <div class="spinner-border colspan m-3" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    // eslint-disable-next-line vue/require-prop-types
    data: {
      default: null,
    },
  },
};
</script>
