<template>
  <!-- Infos permis de construire -->
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header -->
      <div class="card-header px-6" style="background-color: #18ba70">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Infos permis de construire</h3>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="permit" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Numéro DAU</div>
            <div v-if="permit.num_dau" class="pt-2">
              {{ permit.num_dau }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">Adresse</div>
              <div class="pt-2">
                <span v-if="permit.adr_num_ter">{{
                  permit.adr_num_ter + " "
                }}</span>
                <span v-if="permit.adr_typevoie_ter">
                  {{ permit.adr_typevoie_ter + " " }}
                </span>
                <span v-if="permit.adr_libvoie_ter">
                  {{ permit.adr_libvoie_ter + " " }}
                </span>
                <span v-if="permit.adr_localite_ter">
                  {{ permit.adr_localite_ter + " " }}
                </span>
                <span v-if="permit.adr_codpost_ter">
                  {{ permit.adr_codpost_ter + " " }}
                </span>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">Superficie du terrain</div>
              <div v-if="permit.superficie_terrain" class="pt-2">
                {{ permit.superficie_terrain }}
              </div>
              <div v-else class="pt-2">Information non fournie</div>
            </div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">
              Etat d’avancement de l’autorisation
            </div>
            <div v-if="permit.etat_dau" class="pt-2">
              {{ permit.etat_dau }}
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">Date réelle d'autorisation</div>
              <div v-if="permit.date_reelle_autorisation" class="pt-2">
                {{ getMomentDate(permit.date_reelle_autorisation) }}
              </div>
              <div v-else class="pt-2">Information non fournie</div>
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">
                Date réelle d'ouverture de chantier
              </div>
              <div v-if="permit.date_reelle_doc" class="pt-2">
                {{ getMomentDate(permit.date_reelle_doc) }}
              </div>
              <div v-else class="pt-2">Information non fournie</div>
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">
                Nombre de logements total créés
              </div>
              <div v-if="permit.nb_lgt_tot_crees" class="pt-2">
                {{ permit.nb_lgt_tot_crees }}
              </div>
              <div v-else class="pt-2">Information non fournie</div>
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">
                Nombre de logements LLS créés
              </div>
              <div v-if="permit.nb_lls" class="pt-2">
                {{ permit.nb_lls }}
              </div>
              <div v-else class="pt-2">Information non fournie</div>
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">
                Nombre de logements accession sociale créés
              </div>
              <div v-if="permit.nb_lgt_acc_soc_hors_ptz !== null" class="pt-2">
                {{ permit.nb_lgt_acc_soc_hors_ptz }}
              </div>
              <div v-else class="pt-2">Information non fournie</div>
            </div>
          </div>
          <div class="mb-3">
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">
                Nombre de logements prêts à la location sociale
              </div>
              <div v-if="permit.nb_lgt_pret_loc_social !== null" class="pt-2">
                {{ permit.nb_lgt_pret_loc_social }}
              </div>
              <div v-else class="pt-2">Information non fournie</div>
            </div>
          </div>
          <div>
            <div class="d-flex flex-column py-1">
              <div class="fw-bolder text-black">Référence(s) cadastrale(s)</div>
              <div class="pt-2">
                <span v-if="permit.sec_cadastre1">{{
                  permit.sec_cadastre1 + " "
                }}</span>
                <span v-if="permit.num_cadastre1">
                  {{ permit.num_cadastre1 }}
                </span>
                <span v-if="permit.sec_cadastre2">{{
                  "; " + permit.sec_cadastre2 + " "
                }}</span>
                <span v-if="permit.num_cadastre2">
                  {{ permit.num_cadastre2 }}
                </span>
                <span v-if="permit.sec_cadastre3">{{
                  "; " + permit.sec_cadastre3 + " "
                }}</span>
                <span v-if="permit.num_cadastre3">
                  {{ permit.num_cadastre3 }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Promoteur -->
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="card-header px-6 bg-primary">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Promoteur</h3>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="permit" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Promoteur identifié</div>
            <div v-if="permitContacts">
              <div v-if="permitContacts.count > 0">
                <div
                  v-for="contact in permitContacts.results"
                  :key="contact.id"
                  class="pt-2"
                >
                  <div v-if="contact.promoter__name">
                    <router-link
                      v-if="contact.promoter"
                      :to="'/promoters/' + contact.promoter.id"
                    >
                      {{ contact.promoter__name }}
                    </router-link>
                    <router-link
                      v-if="contact.agency"
                      :to="'/agencies/' + contact.agency.id"
                    >
                      (Agence : {{ contact.agency__name }})
                    </router-link>
                  </div>
                  <div v-else class="pt-2">Information non fournie</div>
                </div>
              </div>
              <div v-else class="pt-2">Aucun promoteur identifié</div>
            </div>
            <Loader :data="permitContacts" />
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Raison sociale</div>
            <div v-if="permit.denom_dem" class="pt-2">
              {{ permit.denom_dem }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">SIREN</div>
            <div v-if="permit.siren_dem" class="pt-2">
              {{ permit.siren_dem }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Code postal de la société</div>
            <div v-if="permit.codpost_dem" class="pt-2">
              {{ permit.codpost_dem }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div>
            <div class="fw-bolder text-black">Localité de la société</div>
            <div v-if="permit.localite_dem" class="pt-2">
              {{ permit.localite_dem }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Infos ville -->
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header -->
      <div class="card-header px-6" style="background-color: #33d73e">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0 text-white">Infos ville</h3>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="permit" />
      <!-- Card body -->
      <div class="card-body pt-7 h-400px overflow-scroll">
        <div class="d-flex flex-column text-gray-700">
          <div class="mb-3">
            <div class="fw-bolder text-black">Decote prix ancien</div>
            <div v-if="permit.city.decote_prix_ancien" class="pt-2">
              {{ Math.round(permit.city.decote_prix_ancien * 100) }}
              %
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Zonage abc</div>
            <div v-if="permit.city.zonage_abc" class="pt-2">
              {{ permit.city.zonage_abc }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Nombre de logements ville</div>
            <div v-if="permit.city.nombre_de_logements_ville" class="pt-2">
              {{ permit.city.nombre_de_logements_ville }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Prix immo ancien dvf moyen</div>
            <div v-if="permit.city.prix_immo_ancien_dvf_moyen" class="pt-2">
              {{ permit.city.prix_immo_ancien_dvf_moyen }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Prix immo ancien 3e quartile</div>
            <div v-if="permit.city.prix_immo_ancien_3e_quartile" class="pt-2">
              {{ permit.city.prix_immo_ancien_3e_quartile }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Nb de transactions</div>
            <div v-if="permit.city.nb_de_transactions" class="pt-2">
              {{ permit.city.nb_de_transactions }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Prix de reference hlm ht</div>
            <div v-if="permit.city.prix_de_reference_hlm_ht" class="pt-2">
              {{ permit.city.prix_de_reference_hlm_ht }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">
              Premiere estimation valeur social ht
            </div>
            <div
              v-if="permit.city.premiere_estimation_valeur_social_ht"
              class="pt-2"
            >
              {{ permit.city.premiere_estimation_valeur_social_ht }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Seuil social logements</div>
            <div v-if="permit.city.seuil_social_logements" class="pt-2">
              {{ permit.city.seuil_social_logements }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Seuil social m2</div>
            <div v-if="permit.city.seuil_social_m2" class="pt-2">
              {{ permit.city.seuil_social_m2 }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
          <div class="mb-3">
            <div class="fw-bolder text-black">Taux lls commune</div>
            <div v-if="permit.city.taux_lls_commune" class="pt-2">
              {{ permit.city.taux_lls_commune }}
            </div>
            <div v-else class="pt-2">Information non fournie</div>
          </div>
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import moment from "moment";

export default {
  name: "PermitsDetails",
  components: {
    Loader,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["permit"]),
    ...mapGetters(["permitContacts"]),
  },
  methods: {
    ...mapActions(["getPermit"]),
    ...mapActions(["getPermitContacts"]),
    showPermit(id) {
      this.getPermit(id);
    },
    async getAllPermitContacts() {
      await this.getPermitContacts(this.id);
    },
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.getAllPermitContacts();
  },
};
</script>
