<template>
  <div class="col-md-4">
    <!-- Card -->
    <div class="card h-md-100">
      <!-- Card header-->
      <div class="d-flex card-header px-6">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Suivis</h3>
        </div>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-color-primary"
            data-bs-toggle="modal"
            data-bs-target="#modalFollowUps"
          >
            + Ajouter
          </button>
        </div>
      </div>
      <!-- Loader -->
      <Loader :data="permitFollowUps" />
      <!-- Card body -->
      <div
        v-if="permitFollowUps"
        class="card-body pt-7 h-300px overflow-scroll"
      >
        <div
          v-if="permitFollowUps.count > 0"
          class="d-flex flex-column text-gray-700"
        >
          <div v-for="followup in permitFollowUps.results" :key="followup.id">
            <div class="mb-3">
              <div class="fw-bolder text-primary">
                Date : {{ getMomentDate(followup.created) }}
              </div>
              <div class="pt-2">
                <span v-if="followup.contact"
                  >Contact :
                  <router-link :to="'/contacts/' + followup.contact.id"
                    >{{ followup.contact.last_name }}
                    {{ followup.contact.first_name }}</router-link
                  ></span
                >
                <span v-else>Contact : Aucun contact</span>
              </div>
              <div>
                <span v-if="followup.message && followup.author"
                  >Auteur : {{ followup.author.first_name }}
                  {{ followup.author.last_name }}</span
                >
                <span v-if="followup.message && followup.author == null"
                  >Auteur : aucunes informations</span
                >
              </div>
              <div>
                <span v-if="followup.message"
                  >Message : {{ followup.message }}</span
                >
              </div>
              <span
                class="text-danger"
                @click.prevent="deletePermitFollowUp(followup.id)"
                >Supprimer</span
              >
            </div>
          </div>
        </div>
        <div v-else class="pt-2 text-gray-700">
          Aucun suivi associé à ce permis
        </div>
      </div>
      <!-- Card footer -->
      <div class="mt-8"></div>
    </div>
  </div>
  <!-- Add follow up modal -->
  <div
    id="modalFollowUps"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="modalFollowUpsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h3 class="fw-boldest text-dark fs-1 mb-0">Ajouter un suivi</h3>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-2x">
              <CloseIcon />
            </span>
          </div>
        </div>
        <!-- Modal form -->
        <form @submit.prevent="createFollowUp()">
          <!-- Modal body -->
          <div class="modal-body px-lg-12">
            <div v-if="permitContacts" class="mb-5 fv-row">
              <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>Contact</span>
              </label>
              <div class="d-flex">
                <select
                  v-model="form.contact"
                  name="country"
                  class="form-select form-select-solid me-4"
                >
                  <option value="">Choisir un contact</option>
                  <option
                    v-for="contact in permitContacts.results"
                    :key="contact.id"
                    :value="contact.id"
                  >
                    {{ contact.last_name }} {{ contact.first_name }}
                  </option>
                </select>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#addContactModal"
                >
                  +
                </button>
              </div>
            </div>
            <div class="mb-5 fv-row">
              <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                <span>Message</span>
              </label>
              <textarea
                v-model="form.message"
                class="form-control form-control-solid mb-3"
                rows="3"
              ></textarea>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Loader from "@/components/Loader";
import CloseIcon from "@/components/icons/CloseIcon";

export default {
  name: "PermitFollowUps",
  components: {
    Loader,
    CloseIcon,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        id: null,
        message: "",
        permit: this.id,
        contact: "",
      },
    };
  },
  computed: {
    ...mapGetters(["permitContacts"]),
    ...mapGetters(["permitFollowUps"]),
  },
  methods: {
    ...mapActions(["getPermitContacts"]),
    ...mapActions(["getPermitFollowUps"]),
    ...mapActions(["postFollowUp"]),
    ...mapActions(["deleteFollowUp"]),
    async getContacts() {
      await this.getPermitContacts(this.id);
    },
    async getFollowUps() {
      await this.getPermitFollowUps(this.id);
    },
    async createFollowUp() {
      await this.postFollowUp(this.form);
      await this.getPermitFollowUps(this.id);
    },
    async deletePermitFollowUp(followupId) {
      await this.deleteFollowUp(followupId);
      this.getPermitFollowUps(this.id);
    },
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.getFollowUps();
  },
};
</script>
