<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid mb-12">
    <!-- Header -->
    <div v-if="permit" class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">
            Permis {{ permit.num_dau }}
          </h1>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <Loader :data="permit" />
    <!-- Body -->
    <div v-if="permit" class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
          <PermitDetails :id="permit.id" />
          <PermitContacts
            :id="permit.id"
            :principal_contact="permit.principal_contact"
          />
          <PermitFollowUps :id="permit.id" />
          <CommercialProspecting :id="permit.id" />
        </div>
        <PermitSurfaces :id="permit.id" class="mt-9" />
        <div class="col-md-12 mt-9">
          <!-- Card -->
          <div class="card h-md-100">
            <!-- Card header -->
            <div
              class="d-flex card-header px-6"
              style="background-color: #12694c"
            >
              <div class="card-title m-0">
                <h3 class="fw-boldest m-0 text-white">Infos mairie</h3>
              </div>
              <div class="card-toolbar">
                <router-link
                  :to="'/cities/' + permit.city.id"
                  class="d-flex text-white pt-2"
                >
                  <i class="bi bi-folder-symlink-fill text-white h3 pe-2"></i>
                  <span> Lien vers la mairie</span>
                </router-link>
              </div>
            </div>
            <!-- Loader -->
            <Loader :data="permit" />
            <!-- Card body -->
            <div class="card-body pt-7">
              <div class="d-flex text-gray-700">
                <div class="col-4">
                  <div class="mb-5">
                    <div class="fw-bolder text-black">Nom du maire</div>
                    <div v-if="permit.city.mayor_name" class="pt-2">
                      {{ permit.city.mayor_name }}
                    </div>
                    <div v-else class="pt-2">Information non fournie</div>
                  </div>
                  <div class="mb-5">
                    <div class="fw-bolder text-black">Profession du maire</div>
                    <div v-if="permit.city.mayor_position" class="pt-2">
                      {{ permit.city.mayor_position }}
                    </div>
                    <div v-else class="pt-2">Information non fournie</div>
                  </div>
                  <div>
                    <div class="fw-bolder text-black">Age du maire</div>
                    <div v-if="permit.city.mayor_age" class="pt-2">
                      {{ permit.city.mayor_age }}
                    </div>
                    <div v-else class="pt-2">Information non fournie</div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-5">
                    <div class="fw-bolder text-black">Parti politique</div>
                    <div v-if="permit.city.political_orientation" class="pt-2">
                      {{ permit.city.political_orientation }}
                    </div>
                    <div v-else class="pt-2">Information non fournie</div>
                  </div>
                  <div class="mb-5">
                    <div class="fw-bolder text-black">Date d'élection</div>
                    <div v-if="permit.city.election_date" class="pt-2">
                      {{ getMomentDate(permit.city.election_date) }}
                    </div>
                    <div v-else class="pt-2">Information non fournie</div>
                  </div>
                  <div>
                    <div class="fw-bolder text-black">
                      Client 1loyermoinscher.fr
                    </div>
                    <div
                      v-if="permit.city.is_1loyermoinscher_customer"
                      class="pt-2"
                    >
                      {{
                        permit.city.is_1loyermoinscher_customer ? "Oui" : "Non"
                      }}
                    </div>
                    <div v-else class="pt-2">Information non fournie</div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="mb-5">
                    <div class="fw-bolder text-black">Client Préemption</div>
                    <div v-if="permit.city.is_preemption_customer" class="pt-2">
                      {{ permit.city.is_preemption_customer ? "Oui" : "Non" }}
                    </div>
                    <div v-else class="pt-2">Information non fournie</div>
                  </div>
                  <div class="mb-5">
                    <div class="fw-bolder text-black">Déjà implanté</div>
                    <div class="pt-2">Information non fournie</div>
                  </div>
                  <div>
                    <div class="fw-bolder text-black">
                      Qualité relation mairie
                    </div>
                    <div
                      v-if="permit.city.mayor_relation_quality"
                      class="text-black"
                    >
                      {{ permit.city.mayor_relation_quality }}
                      <i class="bi bi-star-fill text-warning h6"></i>
                    </div>
                    <div v-else class="pt-2">Information non fournie</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Card footer -->
            <div class="mt-8"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useRoute } from "vue-router";
import Loader from "@/components/Loader";
import PermitDetails from "@/components/permits/PermitDetails";
import PermitContacts from "@/components/permits/PermitContacts";
import PermitFollowUps from "@/components/permits/PermitFollowUps";
import CommercialProspecting from "@/components/permits/CommercialProspecting";
import PermitSurfaces from "@/components/permits/PermitSurfaces";
import moment from "moment";

export default {
  name: "PermitsInfos",
  components: {
    Loader,
    PermitDetails,
    PermitContacts,
    PermitFollowUps,
    CommercialProspecting,
    PermitSurfaces,
  },
  computed: {
    ...mapGetters(["permit"]),
  },
  methods: {
    ...mapActions(["getPermit"]),
    showPermit(id) {
      this.getPermit(id);
    },
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    const route = useRoute();
    this.showPermit(route.params.id);
  },
};
</script>
